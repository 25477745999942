import React from "react";
import MainButton from "../MainButton";

function BlogBox({ img, title, description, author, date, category }) {
  return (
    <div>
      <img src={img} alt="blog_img" />
      <p className="font-medium text-[14px] text-[#646464] pt-8 pb-4">
        <i className="fa-solid fa-layer-group text-[#ff0336] text-[16px]"></i>
        &nbsp; De <b>{author}</b> | {date} | {category}
      </p>
      <h2 className="text-[3rem] font-bold mb-6">{title}</h2>
      <p className="font-medium text-[16px] text-[#646464] mb-24">
        {description}
      </p>
      <MainButton
        color={`!text-white`}
        bg={`bg-[#ff0336]`}
        text="află mai multe"
        arrowColor={`!text-white`}
        cN="pricing-cta blog-cta"
      />
    </div>
  );
}

export default BlogBox;
