import { useState } from "react";

function BmiCalc() {
  const [formData, setFormData] = useState({
    weight: 0,
    height: 0,
    age: 0,
    gender: "",
  });
  const [bmi, setBmi] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const calculateBmi = (weight, height) => {
    return weight / ((height * height) / 10000);
  };

  const validateInput = () => {
    const { weight, height, age, gender } = formData;
    if (weight <= 0 || height <= 0 || age <= 0 || !gender) {
      return false;
    }
    return true;
  };

  const calcBmi = (e) => {
    e.preventDefault();
    if (!validateInput()) {
      alert("Vă rugăm să introduceți valori valide pentru greutate, înălțime, vârstă și gen.");
      return;
    }

    const { weight, height } = formData;
    const calculatedBmi = calculateBmi(weight, height);
    setBmi(calculatedBmi.toFixed(1));

    if (calculatedBmi < 18.5) {
      setMessage("Subponderal");
    } else if (calculatedBmi >= 18.5 && calculatedBmi < 24.9) {
      setMessage("Greutate normală");
    } else if (calculatedBmi >= 25 && calculatedBmi < 29.9) {
      setMessage("Supraponderal");
    } else {
      setMessage("Obez");
    }
  };

  return (
    <section className="bmi-section py-[6rem] relative">
      <div className="container page-padding">
        <span className="w-full h-full absolute bg-transparent min620:bg-[#00000063] top-0 left-0"></span>
        <div className="text-white w-[52rem] min620:w-[100%] relative">
          <h2 className="text-[4rem] font-bold leading-[1.1] mb-8">
            Să Calculăm Indicele Tău de Masă Corporală{" "}
            <span className="text-[#FF0336]">BMI</span>
          </h2>
          <p className="text-[16px] text-[#a1a1a1] min620:text-white">
            Determină cu ușurință indicele tău de masă corporală folosind
            instrumentul nostru precis de calcul.
          </p>
          <div className="flex flex-col">
            <form className="flex w-full gap-6 h-[50px] mt-10" onSubmit={calcBmi}>
              <input
                name="weight"
                onChange={handleChange}
                className="w-[50%] bg-transparent text-[14px] border-2 border-[#ffffff7d] pl-6 min450:bg-white min450:text-black"
                type="number"
                placeholder="Greutate / kg"
              />
              <input
                name="height"
                onChange={handleChange}
                className="w-[50%] bg-transparent text-[14px] border-2 border-[#ffffff7d] pl-6 min450:bg-white min450:text-black"
                type="number"
                placeholder="Înălțime / cm"
              />
              <input
                name="age"
                onChange={handleChange}
                className="w-[50%] bg-transparent text-[14px] border-2 border-[#ffffff7d] pl-6 min450:bg-white min450:text-black"
                type="number"
                placeholder="Vârstă"
              />
              <select
                name="gender"
                onChange={handleChange}
                className="w-[50%] bg-transparent text-[14px] border-2 border-[#ffffff7d] pl-6 min450:bg-white min450:text-black"
              >
                <option className="text-black bg-white font-medium text-[16px]" value="">Selectează Gen</option>
                <option className="text-black bg-white font-medium text-[16px]" value="male">Masculin</option>
                <option className="text-black bg-white font-medium text-[16px]" value="female">Feminin</option>
              </select>
            </form>
            <p className="mt-10 gap-3 text-[16px] font-medium flex items-center w-full">
              <span className="w-1/2">
                BMI-ul tău este: &nbsp;
                <span className="text-[#ff0336]">{bmi}</span>
              </span>
              <span className="w-1/2">
                Greutatea ta este: &nbsp;
                <span className="text-[#ff0336]">{message}</span>
              </span>
            </p>
            <button
              onClick={calcBmi}
              style={{ transition: "all 0.3s" }}
              type="submit"
              className="text-[15px] uppercase font-bold mt-10 bg-[#323232] w-[15rem] h-[5rem] hover:bg-[#FF0336]"
            >
              Calculează
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BmiCalc;
