import FooterLogo from "../../images/logo/logo-footer.svg";

function Footer() {
  return (
    <>
      <footer className="bg-white">
        <div className="container page-padding py-[10rem]">
          {/* div-ul footer complet */}
          <div className="flex justify-between min620:flex-col min620:items-center min620:text-center min620:gap-[5rem] !text-left">
            {/* partea cu logo */}
            <div className="flex flex-col w-1/3 gap-8">
              <img src={FooterLogo} alt="logo subsol" className="w-[18rem]" />
              <p className="text-[15px] font-medium text-[#646464]">
                Ridică-ți sănătatea și corpul la un nivel superior cu programul
                nostru complet, conceput pentru a te ajuta să îți atingi
                obiectivele de fitness.
              </p>
              {/* social media */}
              <div className="flex gap-7 text-[18px] text-[#646464] min540:justify-center">
                <i
                  style={{ transition: "all 0.3s" }}
                  className="fa-brands fa-facebook-f bg-[#efefef] p-4 rounded-full px-[14px] hover:bg-[#ff0366] hover:text-white"
                ></i>
                <i
                  style={{ transition: "all 0.3s" }}
                  className="fa-brands fa-twitter bg-[#efefef] p-4 rounded-full hover:bg-[#ff0366] hover:text-white"
                ></i>
                <i
                  style={{ transition: "all 0.3s" }}
                  className="fa-brands fa-pinterest-p bg-[#efefef] p-4 rounded-full px-[14px] hover:bg-[#ff0366] hover:text-white"
                ></i>
                <i
                  style={{ transition: "all 0.3s" }}
                  className="fa-brands fa-youtube bg-[#efefef] p-4 rounded-full hover:bg-[#ff0366] hover:text-white"
                ></i>
              </div>
              <p className="text-[16px] font-medium text-[#646464]">
                Politica de Confidențialitate | © {new Date().getFullYear()}{" "}
                RedGym <br />
                Design de{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://syntaxseeker.com/"
                >
                  CodeArtStudio
                </a>
              </p>
            </div>

            {/* div-ul din mijloc */}
            <div className="flex flex-col gap-8 relative">
              <p className="text-[22px] font-bold footer-main">Vezi Clasele</p>

              <span className="top-[33px] absolute w-[7rem] h-[4px] bg-[#ff0366]"></span>

              <p className="text-[16px] hover:text-[#ff0366] cursor-pointer text-[#646464] font-medium hover:font-bold">
                Clase de Fitness
              </p>
              <p className="text-[16px] hover:text-[#ff0366] cursor-pointer text-[#646464] font-medium hover:font-bold">
                Clase de Aerobic
              </p>
              <p className="text-[16px] hover:text-[#ff0366] cursor-pointer text-[#646464] font-medium hover:font-bold">
                Power Yoga
              </p>
              <p className="text-[16px] hover:text-[#ff0366] cursor-pointer text-[#646464] font-medium hover:font-bold">
                Învățare Aparate
              </p>
              <p className="text-[16px] hover:text-[#ff0366] cursor-pointer text-[#646464] font-medium hover:font-bold">
                Întărire Corporală Completă
              </p>
            </div>

            {/* div-ul din dreapta */}
            <div className="flex flex-col gap-8 relative">
              <p className="text-[22px] font-bold footer-main">
                Ore de Funcționare
              </p>

              <span className="top-[33px] absolute w-[7rem] h-[4px] bg-[#ff0366]"></span>

              <p className="text-[16px] text-[#646464] font-bold">
                Luni - Vineri:
              </p>
              <p className="text-[16px] text-[#646464] font-medium">
                7:00 - 21:00
              </p>
              <p className="text-[16px] text-[#646464] font-bold">Sâmbătă:</p>
              <p className="text-[16px] text-[#646464] font-medium">
                7:00 - 19:00
              </p>
              <p className="text-[16px] text-[#646464] font-bold">
                Duminică - Închis
              </p>
            </div>

            {/* alt div din mijloc */}
            <span></span>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
