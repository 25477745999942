import { useState } from "react";
import ClassesBox from "../components/ClassesBox/ClassesBox";
import Footer from "../components/Footer/Footer";

function Classes() {
  const [showMore, setShowMore] = useState(false);

  const loadMore = () => {
    setShowMore(!showMore);
  };

  const classes = [
    {
      bgImg: "cycling-bg",
      title: "Ciclism",
      trainer: "Dorina Istrate",
      date: "Mie: 9:00",
    },
    {
      bgImg: "meditaion-bg",
      title: "Meditație",
      trainer: "Maria Popescu",
      date: "Vin: 13:00",
    },
    {
      bgImg: "mma-bg",
      title: "Box",
      trainer: "Ion Marin",
      date: "Mar: 16:00",
    },
    {
      bgImg: "karate-bg",
      title: "Karate",
      trainer: "Daria Stoica",
      date: "Sâm: 9:00",
    },
    {
      bgImg: "powerlift-bg",
      title: "Ridicare de Greutăți",
      trainer: "Laurențiu Roșu",
      date: "Lun: 20:00",
    },
    {
      bgImg: "workout-bg",
      title: "Antrenament",
      trainer: "Ștefan Radu",
      date: "Dum: 10:00",
    },
    {
      bgImg: "crossfit-bg",
      title: "Crossfit",
      trainer: "Alexandra Ionescu",
      date: "Mie: 21:00",
    },
    {
      bgImg: "running-bg",
      title: "Alergare",
      trainer: "Sorin Zorina",
      date: "Vin: 6:00",
    },
  ];

  return (
    <>
      <section className="login-section text-center ">
        <div className="login-banner relative justify-center flex">
          <h1 className="text-white absolute bottom-[25px] text-[3rem] font-bold">
            Toate Clasele Disponibile
          </h1>
        </div>

        <div className="container gap-12 page-padding py-[10rem] grid grid-cols-3 md1000:grid-cols-2 min620:grid-cols-1 text-left">
          {classes.slice(0, showMore ? classes.length : 4).map((cls, index) => (
            <ClassesBox
              key={index}
              bgImg={cls.bgImg}
              title={cls.title}
              trainer={cls.trainer}
              date={cls.date}
            />
          ))}
        </div>

        <div className="py-10">
          <button
            className="text-[1.5rem] text-white bg-slate-600 px-14 py-6 font-medium uppercase shadow-2xl mb-14"
            onClick={loadMore}
          >
            {showMore ? "arată mai puține" : "arată mai multe"}
          </button>
        </div>

        <Footer />
      </section>
    </>
  );
}

export default Classes;
