import Footer from "../components/Footer/Footer";
import GirlRunning from "../images/who-we-are/girl-run.png";
import GirlRedBg from "../images/who-we-are/girl-redbg.svg";
import GirlText from "../images/who-we-are/girl-side-text.png";
import GirlWind from "../images/who-we-are/wind.png";
import TitleRed from "../images/who-we-are/title-bg.svg";
import MainButton from "../components/MainButton";
import Img3 from "../images/AboutPage/3.jpg";
import Img4 from "../images/AboutPage/4.jpg";
import Target from "../images/AboutPage/target.png";
import Mountain from "../images/AboutPage/mountain.png";

function About() {
  return (
    <>
      <section className="login-section">
        <div className="login-banner relative justify-center flex">
          <h1 className="text-white absolute bottom-[25px] text-[3rem] font-bold">
            Despre Noi
          </h1>
        </div>
        <div className="container page-padding py-[5rem]">
          <div className="grid grid-cols-[1fr_1fr] h-[60rem] md1000:grid-cols-1">
            {/* div titlu */}
            <div className="flex flex-col !text-left relative items-right justify-center md1000:items-center md1000:!text-center">
              <p className="text-white relative z-10 text-[15px] uppercase font-bold mb-8 ml-20 md1000:ml-3">
                cine suntem
              </p>
              <img
                src={TitleRed}
                alt="fundal text"
                className="w-[21rem] absolute top-[120px] min450:top-[84px] min375:top-[68px]"
              />

              <h2 className="text-[3.6rem] max-w-[53rem] font-bold mb-4">
                Îți Oferim Forță și Sănătate
              </h2>
              <p className="text-[#646464] font-medium text-[15px] max-w-[50rem]">
                La RedGym, ne dedicăm să te ajutăm să îți realizezi corpul
                visurilor tale. Antrenorii noștri experți și nutriționiștii vor
                lucra împreună cu tine pentru a crea un plan personalizat de
                fitness și nutriție care să te ajute să îți atingi obiectivele
                specifice.
              </p>
              <MainButton
                color={`!text-white`}
                bg={`bg-[#ff0336]`}
                goTo="/contact"
                text="contactează-ne"
                arrowColor={`!text-white`}
                cN="pricing-cta blog-cta"
              />
            </div>
            {/* partea vizuală */}
            <div className="relative w-[80%] -top-[90px] left-[40px] md1000:hidden">
              <img
                src={GirlRunning}
                alt="fata care aleargă"
                className="girl-running"
              />
              <img src={GirlRedBg} alt="fundal roșu" className="girl-bg" />
              <img src={GirlText} alt="text pe fundal" className="girl-text" />
              <img src={GirlWind} alt="vânt pe fundal" className="girl-wind" />
            </div>
          </div>
          {/* secțiunea adițională */}
          <div className="mt-[10rem]">
            <div className="grid grid-cols-2 grid-rows-2 w-full min620:grid-cols-1">
              <div className="w-full bg-white h-[43rem] flex flex-col justify-center items-center text-center px-[100px] min800:px-[10px]">
                <img src={Target} alt="imagine țintă" className="w-[9rem]" />
                <h2 className="text-[36px] font-bold mt-3">Istoria Noastră</h2>
                <p className="text-[16px] font-medium text-[#646464] mt-5">
                  Descoperă cum am dezvoltat un spațiu dedicat sănătății și
                  bunăstării, promovând un stil de viață activ și sănătos.
                </p>
              </div>
              {/* imagini diverse */}
              <div className="w-full bg-white h-[43rem]">
                <img
                  src={Img3}
                  alt="despre noi"
                  className="w-full h-full object-cover bg-center"
                />
              </div>
              <div className="w-full bg-white h-[43rem]">
                <img
                  src={Img4}
                  alt="despre noi"
                  className="w-full h-full object-cover bg-center"
                />
              </div>
              <div className="w-full bg-white h-[43rem] flex flex-col justify-center items-center text-center px-[100px] min800:px-[10px]">
                <img src={Mountain} alt="imagine munte" className="w-[9rem]" />
                <h2 className="text-[36px] font-bold mt-3">Misiunea Noastră</h2>
                <p className="text-[16px] font-medium text-[#646464] mt-5">
                  Misiunea noastră este de a inspira și de a motiva fiecare
                  membru să atingă cele mai înalte standarde de performanță și
                  sănătate personală.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default About;
