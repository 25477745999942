import ProgressionImg from "../../images/who-we-are/progresion.png";
import WorkoutImg from "../../images/who-we-are/workout.png";
import NutritionImg from "../../images/who-we-are/nutritions.png";

export const cardList = [
  {
    img: ProgressionImg,
    title: "Progres",
    description:
      "Echipa noastră de experți va colabora cu tine pentru a crea un plan personalizat care să te ajute să atingi succesul pas cu pas.",
  },
  {
    img: WorkoutImg,
    title: "Antrenament",
    description:
      "Cu o varietate de antrenamente din care să alegi, vei avea tot ce îți trebuie pentru a ajunge în cea mai bună formă a vieții tale.",
  },
  {
    img: NutritionImg,
    title: "Nutriție",
    description:
      "Echipa noastră va lucra împreună cu tine pentru a crea un plan alimentar personalizat care să te ajute să îți atingi obiectivele specifice de sănătate.",
  },
];
