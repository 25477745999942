import TitleRed from "../../images/who-we-are/title-bg.svg";
import BlogBox from "./BlogBox";

function Blog() {
  return (
    <>
      <section id="blog" className="bg-white">
        <div className="container page-padding py-[10rem]">
          {/* titlu div -- */}
          <div className="flex flex-col text-center relative items-center">
            <p className="text-white relative z-10 text-[15px] uppercase font-bold mb-8">
              ultimele articole
            </p>
            <img
              src={TitleRed}
              alt="fundal text"
              className="w-[23rem] absolute -top-[10px]"
            />

            <h2 className="text-[3.4rem] font-bold mb-4">
              Noutățile Noastre Recente
            </h2>
            <p className="text-[#646464] font-medium text-[15px]">
              Gymat a luat o galerie tipografică și a amestecat-o
              <br /> pentru a crea un model de tipar.
            </p>
          </div>

          {/* casete de blog */}
          <div className="flex gap-6 w-full mt-[5rem] md1200:justify-center flex-wrap">
            <BlogBox
              bgClass="box1Bg"
              date="22.03.2022"
              title="Yoga pentru Toți în 2023"
              description="Acest program este conceput pentru a face practica de yoga benefică pentru oameni de toate vârstele, abilitățile și medii."
            />
            <BlogBox
              bgClass="box2Bg"
              date="13.09.2022"
              title="Revenirea la CrossFit După Vacanță"
              description="Află cum să te reintegrezi ușor în rutina ta de CrossFit după o vacanță, cu sfaturi și strategii pentru succes."
            />
            <BlogBox
              bgClass="box3Bg"
              date="28.06.2022"
              title="Cunoaște-o pe Ambasadorul Fitness Grace"
              description="Fă cunoștință cu Grace, o entuziastă a fitnessului și ambasadoare dedicată, pasionată de a ajuta alții să-și atingă obiectivele de fitness."
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
