import React from "react";
import TestimonialBox from "./TestimonialBox";

function TestimonialBoxes({ testimonials, currentIndex }) {
  const { text, name, job } = testimonials[currentIndex];

  return (
    <TestimonialBox
      text={text}
      name={name}
      job={job}
    />
  );
}

export default TestimonialBoxes;
