import Img1 from "../../images/GalleryPage/1.jpg";
import Img2 from "../../images/GalleryPage/2.jpg";
import Img3 from "../../images/GalleryPage/3.jpg";
import Img4 from "../../images/GalleryPage/4.jpg";
import Img5 from "../../images/GalleryPage/5.jpg";
import Img6 from "../../images/GalleryPage/6.jpg";
import Img7 from "../../images/GalleryPage/7.jpg";
import Img8 from "../../images/GalleryPage/8.jpg";
import Img9 from "../../images/GalleryPage/9.jpg";
import Img10 from "../../images/GalleryPage/10.jpg";
import Img11 from "../../images/GalleryPage/11.jpg";
import Img12 from "../../images/GalleryPage/12.jpg";

export const galleryImg = [
  { img: Img1, id: 1 },
  { img: Img2, id: 2 },
  { img: Img3, id: 3 },
  { img: Img4, id: 4 },
  { img: Img5, id: 5 },
  { img: Img6, id: 6 },
  { img: Img7, id: 7 },
  { img: Img8, id: 8 },
  { img: Img9, id: 9 },
  { img: Img10, id: 10 },
  { img: Img11, id: 11 },
  { img: Img12, id: 12 },
];
