import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo/logo-navbar.svg";
import NavList from "../Nav/NavList";
import LogoSide from "../../images/logo/logo-footer.svg";
import SideImg1 from "../../images/sidebar/1.jpg";
import SideImg2 from "../../images/sidebar/2.jpg";
import SideImg3 from "../../images/sidebar/3.jpg";
import SideImg4 from "../../images/sidebar/4.jpg";
import SideImg5 from "../../images/sidebar/5.jpg";
import SideImg6 from "../../images/sidebar/6.jpg";

function Navbar() {
  const [spin, setSpin] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [sidebar, setSideBar] = useState(false);
  const [hamburger, setHamburger] = useState(false);

  const joinSpin = () => setSpin(true);
  const stopSpin = () => setSpin(false);

  // sticky navbar - bg black
  const handleScroll = () => {
    if (window.scrollY > 10) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // logo
  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // sidebar
  const toggleSideBar = () => setSideBar(!sidebar);

  // hamburger menu
  const toggleHamburgerMenu = () => setHamburger(!hamburger);

  return (
    <>
      <nav
        className={`flex flex-row bg-transparent items-center justify-between py-8 px-12 fixed top-0 left-0 right-0 w-full z-50 ${
          sticky ? "shadow-xl !bg-black" : ""
        }`}
      >
        <Link to="/">
          <img src={Logo} alt="logo_img" onClick={goTop} className="w-full h-auto" />
        </Link>
        <div className="navlist-nav">
          <NavList />
        </div>
        <div className="flex items-center gap-10">
          <div className="flex gap-10">
            {/* mobile menu */}
            <div
              className={`flex flex-col fixed w-full h-screen bg-black top-0 z-[9999999999] py-[60px] px-[40px] ease-in-out duration-500 ${
                hamburger ? "left-0" : "-left-[100%]"
              }`}
            >
              <i
                onClick={toggleHamburgerMenu}
                className="fa-solid fa-xmark text-[#ff0336] text-[3.3rem] cursor-pointer self-end"
              ></i>

              {/* links */}
              <ul className="text-center flex flex-col gap-10 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
                <li onClick={toggleHamburgerMenu}>
                  <a
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-[2rem] font-medium hover:text-[#ff0336] ease-in duration-200 text-[#fff]"
                    href="/#home"
                  >
                    Acasă
                  </a>
                </li>
                <li onClick={toggleHamburgerMenu}>
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-[2rem] font-medium hover:text-[#ff0336] ease-in duration-200 text-[#fff]"
                    to="/about"
                  >
                    Despre Noi
                  </Link>
                </li>
                <li onClick={toggleHamburgerMenu}>
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-[2rem] font-medium hover:text-[#ff0336] ease-in duration-200 text-[#fff]"
                    to="/schedule/monday"
                  >
                    Orar
                  </Link>
                </li>
                <li onClick={toggleHamburgerMenu}>
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-[2rem] font-medium hover:text-[#ff0336] ease-in duration-200 text-[#fff]"
                    to="/gallery"
                  >
                    Galerie
                  </Link>
                </li>
                <li onClick={toggleHamburgerMenu}>
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-[2rem] font-medium hover:text-[#ff0336] ease-in duration-200 text-[#fff]"
                    to="/blog"
                  >
                    Blog
                  </Link>
                </li>
                <li onClick={toggleHamburgerMenu}>
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-[2rem] font-medium hover:text-[#ff0336] ease-in duration-200 text-[#fff]"
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
                <li onClick={toggleHamburgerMenu}>
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-[2rem] font-medium hover:text-[#ff0336] ease-in duration-200 text-[#fff]"
                    to="/pricing"
                  >
                    Prețuri
                  </Link>
                </li>
                <li onClick={toggleHamburgerMenu}>
                  <Link
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-[2rem] font-medium hover:text-[#ff0336] ease-in duration-200 text-[#fff]"
                    to="/classes"
                  >
                    Clase
                  </Link>
                </li>
              </ul>
            </div>

            {/* sidebar */}
            <div
              className={`flex flex-col fixed w-[40rem] min450:w-full h-[100vh] bg-white top-0 z-[9999999999] p-[45px] gap-24 overflow-x-hidden ease-in-out duration-[0.5s] ${
                sidebar ? "left-0" : "-left-[100%]"
              }`}
            >
              {/* logo & X */}
              <div className="flex justify-between items-center">
                <img src={LogoSide} alt="logo_img" className="w-[13rem]" />
                <i
                  onClick={toggleSideBar}
                  className="fa-solid fa-xmark text-[#ff0336] text-[3.3rem] cursor-pointer"
                ></i>
              </div>
              {/* about us */}
              <div className="flex flex-col gap-6">
                <h3 className="text-[2rem] font-bold">Despre Noi</h3>
                <p className="text-[1.6rem] font-medium text-[#000000b1]">
                  Află cine suntem și ce ne face unici. Suntem o sală de fitness condusă de comunitate, dedicată să ofere experiențe personalizate de antrenament pentru toate nivelurile de entuziaști ai fitnessului, într-un mediu primitor și de suport.
                </p>
              </div>
              {/* gallery */}
              <div className="flex flex-col gap-6">
                <h3 className="text-[2rem] font-bold">Galerie</h3>
                <div className="grid grid-cols-3 grid-rows-2 gap-4">
                  <img
                    src={SideImg1}
                    alt="sidebar_gallery"
                    className="rounded-xl cursor-pointer "
                  />
                  <img
                    src={SideImg2}
                    alt="sidebar_gallery"
                    className="rounded-xl cursor-pointer "
                  />
                  <img
                    src={SideImg3}
                    alt="sidebar_gallery"
                    className="rounded-xl cursor-pointer "
                  />
                  <img
                    src={SideImg4}
                    alt="sidebar_gallery"
                    className="rounded-xl cursor-pointer "
                  />
                  <img
                    src={SideImg5}
                    alt="sidebar_gallery"
                    className="rounded-xl cursor-pointer "
                  />
                  <img
                    src={SideImg6}
                    alt="sidebar_gallery"
                    className="rounded-xl cursor-pointer "
                  />
                </div>
              </div>
              {/* contact */}
              <div className="flex flex-col gap-6">
                <h3 className="text-[2rem] font-bold">Informații de Contact</h3>
                <p className="text-[1.6rem] font-medium text-[#000000b1] hover:text-[#ff0336] cursor-pointer ease-in duration-200">
                  <i className="fa-solid fa-location-dot text-[#ff0336]"></i>
                  &nbsp; Afi Palace, Calomfirescu nr1
                </p>
                <p className="text-[1.6rem] font-medium text-[#000000b1] hover:text-[#ff0336] cursor-pointer ease-in duration-200">
                  <i className="fa-solid fa-phone text-[#ff0336]"></i>&nbsp;
                  +40770112332
                </p>
                <p className="text-[1.6rem] font-medium text-[#000000b1] hover:text-[#ff0336] cursor-pointer ease-in duration-200">
                  <i className="fa-solid fa-envelope text-[#ff0336]"></i>
                  &nbsp; redgym@gymail.com
                </p>
              </div>
              {/* follow us */}
              <div className="flex flex-col gap-6">
                <h3 className="text-[2rem] font-bold">Urmărește-ne</h3>
                <div className="flex gap-5">
                  <span className="bg-[#ff0336] rounded-full py-[12px] px-[17px] cursor-pointer">
                    <i className="fa-brands fa-facebook-f text-[2.2rem] text-white"></i>
                  </span>
                  <span className="bg-[#ff0336] rounded-full py-[12px] px-[12px] cursor-pointer">
                    <i className="fa-brands fa-twitter text-[2.2rem] text-white"></i>
                  </span>
                  <span className="bg-[#ff0336] rounded-full py-[10px] px-[13px] cursor-pointer">
                    <i className="fa-brands fa-instagram text-[2.2rem] text-white"></i>
                  </span>
                </div>
              </div>
            </div>

            {/* hamburger */}
            <i
              onClick={toggleHamburgerMenu}
              className="fa-bars fa-solid text-white text-4xl cursor-pointer hover:text-[#FF0336] ease-in duration-200"
            ></i>
            {/* account */}
            <Link onClick={goTop} to="/" >
              <i className="fa-regular fa-user text-white text-4xl cursor-pointer hover:text-[#FF0336] ease-in duration-200"></i>
            </Link>
            {/* sidebar */}
            <i
              onClick={toggleSideBar}
              className="fa-regular fa-chart-bar text-white text-4xl cursor-pointer hover:text-[#FF0336] ease-in duration-200"
            ></i>
          </div>
          {/* spin box */}
          <div className="border-[rgb(255,255,255,0.3)] border-solid border-2 p-2 rounded-md min620:hidden">
            <Link
              onClick={goTop}
              to={"/contact"}
              onMouseEnter={joinSpin}
              onMouseLeave={stopSpin}
              className="flex items-center "
            >
              <i
                className={`fa-solid fa-plus bg-[#FF0336] text-white text-2xl py-3 px-4 rounded-md ${
                  spin ? "nav-btn-hover" : ""
                }`}
              ></i>
              <h3 className="text-white text-[14px] font-bold uppercase ml-4 mr-8 tracking-wider">
                Înscrie-te la cursuri acum
              </h3>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
