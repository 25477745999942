import React, { useState, useEffect } from "react";
import { galleryImg } from "./GalleryFiles";

const itemsPerPage = 8;

function GalleryPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [images, setImages] = useState([]);

  useEffect(() => {
    console.log('Setting images for current page:', currentPage);
    setImages(galleryImg.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
  }, [currentPage]);

  const totalPages = Math.ceil(galleryImg.length / itemsPerPage);
  console.log('Total pages:', totalPages);

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 170);
    setCurrentPage(newPage);
    console.log('Changing to new page:', newPage);
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-7 md1000:grid-cols-2 min540:grid-cols-1">
        {images.map((image) => (
          <div key={image.id}>
            <img alt="gallery_img" className="w-full h-auto" src={image.img} />
          </div>
        ))}
      </div>

      <div className="flex justify-center gap-3 mt-32">
        {currentPage > 1 && (
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="text-black inline-flex items-center justify-center text-center text-[18px] border border-solid border-[#dee2e6] w-[48px] h-[45px] font-medium rounded-md bg-white "
          >
            <i className="fa-solid fa-angles-left"></i>
          </button>
        )}
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`${
              index + 1 === currentPage ? "text-white bg-[#ff0336]" : "text-black bg-white"
            } inline-flex items-center justify-center text-center text-[18px] border border-solid border-[#dee2e6] w-[48px] h-[45px] font-medium rounded-md`}
          >
            {index + 1}
          </button>
        ))}
        {currentPage < totalPages && (
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="text-black inline-flex items-center justify-center text-center text-[18px] border border-solid border-[#dee2e6] w-[48px] h-[45px] font-medium rounded-md bg-white "
          >
            <i className="fa-solid fa-angles-right"></i>
          </button>
        )}
      </div>
    </>
  );
}

export default GalleryPage;
