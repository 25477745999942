import React from 'react';
import BlogBox from './BlogBox';
import Img1 from "../../images/blogpage/1.jpg";
import Img2 from "../../images/blogpage/2.jpg";
import Img3 from "../../images/blogpage/3.jpg";
import Img4 from "../../images/blogpage/4.jpg";
import Img5 from "../../images/blogpage/5.jpg";
import Img6 from "../../images/blogpage/6.jpg";
import Img7 from "../../images/blogpage/7.jpg";

function BlogList() {
  const blogData = [
    {
      img: Img1,
      title: "Yoga Pentru Toți în 2024",
      description: "Yoga nu mai este doar o practică spirituală antică, ci o modalitate accesibilă pentru toți de a îmbunătăți sănătatea mintală și fizică. În 2022, am lansat programe de yoga care se adresează tuturor nivelelor, de la începători până la avansați, promovând un stil de viață echilibrat și plin de energie.",
      author: "Admin",
      date: "Martie 11, 2024",
      category: "Yoga"
    },
    {
      img: Img2,
      title: "Reîntoarcerea la CrossFit După Vacanță",
      description: "Revenirea la rutina de CrossFit după o vacanță poate părea descurajantă, dar cu planul nostru structurat, te vom readuce rapid în formă. Află cum să reîncepi antrenamentele eficient, minimizând riscul de rănire și maximizând plăcerea și progresul.",
      author: "Admin",
      date: "Aprilie 10, 2024",
      category: "CrossFit"
    },
    {
      img: Img3,
      title: "Întâlnește-o pe Ana, Ambasadorul Nostru de Fitness",
      description: "Ana, ambasadoarea noastră dedicată fitnessului, inspiră comunitatea noastră prin pasiunea și dedicarea ei. Cu o poveste personală impresionantă de transformare și un angajament ferm pentru fitness, Ana te motivează să-ți depășești limitele și să atingi obiectivele de sănătate.",
      author: "Admin",
      date: "Mai 5, 2024",
      category: "Fitness"
    },
    {
      img: Img4,
      title: "Cele Mai Bune Echipamente Europene Direct la Sala Noastră",
      description: "Noi aducem cele mai bune echipamente europene direct la sala noastră de sport. Investim constant în echipamente de top pentru a asigura că membrii noștri beneficiază de cele mai bune și mai sigure opțiuni pentru antrenamentele lor, indiferent de disciplina aleasă.",
      author: "Admin",
      date: "Iunie 1, 2024",
      category: "Echipamente"
    },
    {
      img: Img5,
      title: "Îmbunătățește-ți Fitness-ul cu Sala Noastră",
      description: "Dacă ești gata să-ți iei fitnessul la un alt nivel, sala noastră este locul perfect. Cu o gamă largă de echipamente de ultimă generație și antrenori experimentați, oferim un mediu stimulativ și suportiv care te ajută să atingi cele mai înalte standarde ale performanței personale.",
      author: "Admin",
      date: "Iulie 14, 2024",
      category: "Fitness"
    },
    {
      img: Img6,
      title: "Cum Să Menții Activi Copiii Pe Tot Parcursul Verii",
      description: "Vară este momentul perfect pentru a-ți încuraja copiii să fie activi și să se bucure de mișcare. Descoperă programele noastre speciale de vară pentru copii, concepute pentru a-i ține în mișcare și pentru a le cultiva dragostea pentru un stil de viață sănătos și activ.",
      author: "Admin",
      date: "August 9, 2024",
      category: "Activități pentru copii"
    },
    {
      img: Img7,
      title: "Îmbunătățește-ți Fitness-ul cu Noua Noastră Provocare la Sală",
      description: "Provocarea noastră nouă de la sală este concepută să te motiveze și să-ți testeze limitele. Participă la noua noastră provocare de fitness și descoperă ce potențial neexplorat ai. Alătură-te comunității noastre și împinge-ți granițele la maximum!",
      author: "Admin",
      date: "Septembrie 20, 2024",
      category: "Provocare"
    },
  ];

  return (
    <div className="flex flex-col gap-28">
      {blogData.map((blog, index) => (
        <BlogBox
          key={index}
          img={blog.img}
          title={blog.title}
          description={blog.description}
          author={blog.author}
          date={blog.date}
          category={blog.category}
        />
      ))}
    </div>
  );
}

export default BlogList;
