import TitleRed from "../../images/who-we-are/title-bg.svg";
import PricingBox from "./PricingBox";

import Img1 from "../../images/pricing/img1.jpg";
import Img2 from "../../images/pricing/img2.jpg";
import Img3 from "../../images/pricing/img3.jpg";

function Pricing() {
  return (
    <section className="pricing-section py-[12rem] relative">
      <div className="container page-padding ">
        {/* title div -- */}
        <div className="flex flex-col text-center relative items-center">
          <p className="text-white relative z-10 text-[15px] uppercase font-bold mb-8">
            PLANURI DE PREȚURI
          </p>
          <img
            src={TitleRed}
            alt="text_bg"
            className="w-[23rem] absolute -top-[10px]"
          />
          <h2 className="text-[3.4rem] font-bold mb-4">
            Planuri Exclusive de Prețuri
          </h2>
        </div>
        {/* pricing boxes */}
        <div className="flex gap-10 mt-32 relative z-[2] md1000:flex-col md1000:items-center ">
          <PricingBox
            img={Img1}
            price="39"
            level="Începători"
            features={["Acces la aparate", "2 clase grup", "Consultanță nutrițională"]}
          />
          <PricingBox
            img={Img2}
            price="65"
            level="Intermediari"
            features={["Acces nelimitat", "5 clase grup", "Plan alimentar", "2 sesiuni cu antrenor personal"]}
          />
          <PricingBox
            img={Img3}
            price="100"
            level="Avansați"
            features={["Acces total", "Clase nelimitate", "Supervizare completă antrenor", "Evaluare lunară"]}
          />
        </div>
      </div>
    </section>
  );
}

export default Pricing;
